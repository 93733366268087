import { graphql } from "gatsby";
import * as React from "react";
import get from "lodash/get";

// Components
import Layout from "../layout";

// Sections
import {
  Content,
} from "../sections/blog-post";

const sections = {
  Content: Content,
};

class BlogPostTemplate extends React.Component {
  render() {
    const template_data = get(this.props, "data.contentfulTemplateBlogPost");
    const Section = sections["Content"];

    return (
      <Layout
        seo={{
          description: template_data.description ? template_data.description.description : undefined,
          path: template_data.path,
          title: template_data.title,
        }}
      >
        <Section data={template_data}/>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const templateQuery = graphql`
  query BlogPostTemplateQuery($path: String!) {
    contentfulTemplateBlogPost(path: { eq: $path }) {
      path
      title
      description {
        description
      }
      author
      date(formatString: "MMMM DD, YYYY")
      image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH,
          placeholder: BLURRED
        )
      }
      body {
        raw
        references {
          ... on ContentfulComponentBlogVideo {
            contentful_id
            __typename
            url
            videoName
          }
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(layout: CONSTRAINED, quality: 80, formats: [WEBP, AUTO], placeholder: BLURRED)
            title
          }
        }
      }
    }
  }
`;
