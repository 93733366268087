// Core
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

//images
import a_logo from "../../../images/logo-white-large.png";

import {
  RichText,
} from "../../../components/";

class Content extends React.Component {
  render() {
    const data = this.props.data;

    return (
      <div>
        <GatsbyImage
          alt={data.image.title}
          image={data.image.gatsbyImageData}
        />
        <div className="relative bg-light-purple px-6 py-10 shadow-xl">
          <div className="cContainer pr-10">
            <h1 className="text-h3-m md:text-h3 text-white">{data.title}</h1>
            <h5 className="text-white">{data.author}</h5>
            <h6 className="mt-10 text-white">{data.date}</h6>
          </div>
          <div className="absolute -bottom-7 right-0 max-w-[150px] md:max-w-none">
            <img
              src={a_logo}
              alt="Logo"
            />
          </div>
        </div>
        <div className="px-6 py-16">
          <div className="cContainer">
            <div className="rte">
              <RichText json={JSON.parse(data.body.raw)} references={data.body.references} />
            </div>

            <div className="py-16">
              <div className="cContainer text-center flex items-center flex-col">
                <div className="w-full text-left">
                  <h2 className="text-h3 md:text-h1-m text-black font-bold leading-[120%] mb-2 sm:mb-6">Sign up for our Newsletter</h2>
                </div>
                <div className="mt-2 sm:mt-6 w-full blog-newsletter text-left">
                  <p>Register for our newsletter to recieve notifications on our blogs, updates on store launch, and upcoming promotions.</p>
                </div>
                <div className="mt-4 sm:mt-10 text-left flex items-center w-full">
                  <form 
                  className="mt-4 max-w-[520px] w-full text-purple" 
                  name="newsletter"
                  action="/blogs" 
                  method="POST" 
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  >
                    <input name="form-name" type="hidden" value="newsletter"/>
                    <div hidden>
                      <label>
                        Don&apos;t fill this part out: <input name="bot-field"/>
                      </label>
                    </div>
                    <label className="text-p-xl">
                      <input type="email" name="email" placeholder="Email" />
                    </label>
                    <button className="button button--secondary button--large mt-12" type="submit">Register</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

Content.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Content;
